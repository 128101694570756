import React, { useState, useEffect } from "react";
import logo from "../assets/Images/logo.jpg";
import { Link, NavLink } from "react-router-dom";
import Loader from "../Components/Loader";
import axios from "axios";
import { Config } from "../Config";

import Policy1 from "../assets/Images/policy/AGH policy instructions dated 04.01.2021.pdf"
import Policy2 from "../assets/Images/policy/Letter AGH 2013 amendment 2023.pdf"
import Policy3 from "../assets/Images/policy/Notification_Affordable_House_Policy_2013_Finalised 18.08.2013.pdf"

const Navbar = () => {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [policy, setPolicyPage] = useState([]);
  const [contactSection, setContactSection] = useState({});

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  });

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    axios.get(`${Config.apiUrl}/policy`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.policy_page;
        setPolicyPage(data.policy);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);

      });
  }, []);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/contactpage`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.contact_page;
        setContactSection(data.contact_section);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);

      });
  }, []);

  // Get the height of the entire document
  const fullPageHeight = Math.max(
    document.body.scrollHeight, document.body.offsetHeight,
    document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight
  );



  return (
    <div className="border-bottom">
      <nav
        className={`pd-navbar shadow ${stickyNavbar ? fullPageHeight >= 900 ? "skicky-navbar" : "absolute-navbar" : ""
          }`}
      >
        <div className=" bg-blue py-2 top-contact">
          <div className="d-md-flex justify-content-md-end justify-content-center">
            <div className="mx-md-3 width-100">
              <i className="bi bi-telephone-fill text-white me-2"></i>
              <a
                href={`tel:${contactSection.contact_numbers}`}
                className="text-light text-decoration-none"
              >
                {contactSection.contact_numbers}
              </a>
            </div>
            <div className="mx-md-3 width-100">
              <i className="bi bi-envelope-fill text-white me-2"></i>
              <a
                href={`mailto:${contactSection.contact_mails}`}
                className="text-light text-decoration-none"
              >
                {contactSection.contact_mails}
              </a>
            </div>
          </div>
        </div>
        <div className="container-box ">
          <div className="row justify-space-between align-items-end ">
            <div className="col-md-3 col-6 text-md-start ">
              <Link to={`${Config.appUrl}`} className="navbar-brand">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo"
                  onClick={() => window.scrollTo(0, 0)}
                ></img>
              </Link>
            </div>
            <div className="col-md-9 col-6 text-end position-relative">
              <ul className={`navlinks ${mobileMenu && "show-menu"}`}>
                {/*  */}
                <li>
                  <NavLink to={`${Config.appUrl}`}>HOME</NavLink>
                </li>

                <li>
                  <NavLink
                    to={`${Config.appUrl}all-projects`}
                    state={{
                      sector: "I",
                    }}
                  >
                    Projects
                  </NavLink>
                </li>


                <li className="d-flex align-items-center">
                  
                    <ul className="dropdown w-100 ">
                      <li className="dropdown-item " id="dropdown-toggle">

                        <NavLink
                          className=" position-relative border-0"
                          to={`${Config.appUrl}affordable/policy`}
                          state={{
                            sector: "I",
                          }}
                        >
                          Policy
                          <i
                            className="dropdown-toggle item-icon ms-2"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          ></i>
                        </NavLink>

                        <ul
                          className={`submenu ${isDropdownOpen && "d-block"}`}
                          id="submenu"
                        >
                          <li>
                            <a href={Policy1} download className="text-capitalize">
                            AGH policy instructions
                            </a>
                          </li>
                          <li>
                            <a href={Policy2} download className="text-capitalize">
                            Letter AGH 2013 amendment
                            </a>
                          </li>
                          <li>
                            <a href={Policy3} download className="text-capitalize">
                            Notification Affordable Hou..
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                </li>


                {/* <li><a href="#highlight">HIGHLIGHTS</a></li> */}
                {/* <li className="d-flex align-items-center">
                  <div className="dropdown w-100 show border-0 ">
                    <ul className="dropdown w-100 ">
                      <li className="dropdown-item " id="dropdown-toggle">
                        <button className="btn position-relative">
                          {" "}
                          Refund Policy
                          <i
                            className="dropdown-toggle item-icon ms-2"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          ></i>
                        </button>
                        <ul
                          className={`submenu ${isDropdownOpen && "d-block"}`}
                          id="submenu"
                        >
                          {policy.map((item, index) => (
                            <li key={index}>
                              <Link
                               to={`${Config.appUrl}refund-policy/${encodeURIComponent(item.project_name.toLowerCase().replace(/\s+/g, '-'))}`}
                               state={{ name:item.project_name}}>{item.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li> */}
                {/* <li className="d-flex align-items-center">
                  <div className="dropdown w-100 show border-0 ">
                    <ul className="dropdown w-100 ">
                      <li className="dropdown-item " id="dropdown-toggle">
                        <button className="btn position-relative">
                          {" "}
                          Refund Policy
                          <i
                            className="dropdown-toggle item-icon ms-2"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          ></i>
                        </button>
                        <ul
                          className={`submenu ${isDropdownOpen && "d-block"}`}
                          id="submenu"
                        >
                          <li>
                            <Link to="/refund-prolicy-bazaar-89">
                              Bazaar 89
                            </Link>
                          </li>
                          <li>
                            <Link to="/refund-prolicy-bazaar-90">
                              Bazaar 90
                            </Link>
                          </li>
                          <li>
                            <Link to="/refund-prolicy-bazaar-93">
                              Bazaar 93
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li>
                  <NavLink to={`${Config.appUrl}get-in-touch`} className="button text-white rounded-5">Contact Us</NavLink>
                </li>
              </ul>
              <i
                className={`bi bi-${mobileMenu ? "x" : "list"} fs-2 menu-btn`}
                onClick={() => setMobileMenu(!mobileMenu)}
              ></i>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
