import React, { useEffect, useState } from 'react'
import ListBox from './Elements/ListBox'

// import topBanner from "../assets/Images/banner-image.png";
import ImageSlider from './ImageSlider';
import { Config } from '../Config';
import axios from 'axios';
import moment from 'moment';

const MainHomeSection = ({ data }) => {

  const [swarnim1_download, setswarnim1_download] = useState([])
  const [swarnim2_download, setswarnim2_download] = useState([])
  const [projectInfo, setProjectInfo] = useState([])
  const [policyData, setPolicyData] = useState([])
  const [newsData, setNewsData] = useState([])



  useEffect(() => {
    axios.get(`${Config.apiUrl}/project_info`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        setProjectInfo(
          {
            heading: {
              text: "Ongoing Projects",
              bgcolor: "bg-green"
            },
            list: response.data.project_page.project_info
              .filter(pr => pr.status === 1)
              .map(item => ({
                title_sm: "",
                title_lg: item.project_name,
                name: item.project_name,
                url: `${Config.appUrl}projectdetails/${item.project_name.replaceAll(" ", "-").toLowerCase()}`

              }))
          }
        )


        setPolicyData(
          {
            heading: {
              text: "Closed Projects ",
              bgcolor: "bg-green"
            },
            list: response.data.project_page.project_info
              .filter(pr => pr.status === 0)
              .slice(0, 2).map(item => ({
                title_sm: "",
                title_lg: `${item.project_name}`,
                name: item.project_name,
                url: `${Config.appUrl}projectdetails/${item.project_name.replaceAll(" ", "-").toLowerCase()}`
              }))?.slice(0,3)
          }
        )

        setNewsData(response.data.project_page.news)

        // calculating recent added 2 projects
        const currentDate = new Date();
        // Function to calculate the difference between two dates
        const dateDiff = (date1, date2) => Math.abs(date1 - date2);

        // Calculate differences and sort
        const sortedDates =
          response.data.project_page.project_info
            .filter(pr => pr.status === 1)
            .map(project_data => {
              const date = new Date(project_data.updated_at);
              return { date, difference: dateDiff(date, currentDate), pname: project_data.project_name };
            }).sort((a, b) => a.difference - b.difference);

        const projects_download_data = sortedDates.slice(0, 2).map((project) => ({
          heading: {
            text: `Download Section ( ${project.pname} )`,
            bgcolor: "bg-blue"
          },
          list: response.data.project_page.project_download
            .filter(item => item.project_name === project.pname)
            .slice(0, 4)
            .map(item => ({
              title_sm: item.document_name,
              title_lg: "",
              file: item.download_pdf
            }))
        }))

        setswarnim1_download(projects_download_data[0])
        setswarnim2_download(projects_download_data[1])

      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
      });
  }, []);


  return (
    <section className="py-5 pt-4" style={{ backgroundColor: '#135a941b' }}>
      <div className="container-box">
        <div className="row">
          <div className="col-md-3">
            <ListBox data={projectInfo} highlightText={true} />
            <ListBox data={policyData} />
            <ListBox data={swarnim1_download} />
            <ListBox data={swarnim2_download} />
          </div>
          <div className="col-md-9">
            <div className="text-center border-bottom bg-white mb-4">
              <img src={`${Config.imageUrl}/${data.banner_img}`} alt="banner" className="img-fluid w-100 border p-1" />
            </div>
            <div>
              <ImageSlider data={data} />
            </div>

            <div>
              <div className="">
                <div className="mt-4">
                  <h5 className="fw-bold text-animated text-capitalize">
                    News Updates
                  </h5>
                </div>

                <div className="mt-3">
                  <div className="marquee-container">
                    <div className="marquee">
                      <div className="row">
                        {
                          newsData?.map((news, index) => {
                            return (
                              <a href={`${Config.docUrl}${news.pdf}`} target='_blank'
                              className='d-block text-dark text-decoration-none'>
                              <div key={news.id} className="d-flex flex-column">
                                <div className="title-marquee">
                                  <i className="bi bi-calendar-date me-3 text-blue"></i>
                                  <h4 className="heading-marquee">{moment(news.date).format("DD MMM YYYY")}
                                    <span className='text-animated d-inline rounded ms-1 badge lh-sm' style={{ fontSize: "12px" }}> <i className='bi bi-arrow-left fs-6 me-1'></i> Check Now  </span>
                                  </h4>
                                </div>

                                <p className="paragraph-marquee">
                                  {news.news}
                                </p>
                                <div className="line-marquee"></div>
                              </div>
                              </a>
                            )
                          })
                        }

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-3">
            <ListBox data={carddata3} />
            <ListBox data={carddata4} />
          </div> */}


        </div>
      </div>
    </section>
  )
}

export default MainHomeSection