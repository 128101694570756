import React, { useEffect, useState } from "react";
// import Information from "../Information";
import Navbar from "../Navbar";
// import UltimusXl from "../../assets/Images/Ultimus details.xlsx";
import { Link, useLocation, useParams } from "react-router-dom";


import { Config } from "../../Config";
import axios from "axios";
import Loader from "../Loader";
import swal from "sweetalert";

const Swarnim1 = () => {

  const location = useLocation();
  const [projectName, setProjectName] = useState('');

  const [loading, setLoading] = useState(true);
  const [drop_sizes, setdrop_sizes] = useState([]);
  const [terms, setTerms] = useState([]);
  const [priceList, setpriceList] = useState([]);
  const [price, setPrice] = useState([]);
  const [into, setIntro] = useState([]);
  const [project_download, setProjectDownload] = useState([]);

  const { projectname } = useParams()

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  function capitalizeText(text) {
    return text.toLowerCase().replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());
  }

  useEffect(() => {
    const p_name = capitalizeText(projectname.replaceAll("-", " "))
    if (localStorage.getItem('projectName') != p_name) {
      localStorage.setItem('projectName', p_name)
    }
  }, [])

  useEffect(() => {
    if (location.state?.project) {
      setProjectName(location.state.project);
      localStorage.setItem('projectName', location.state.project);
    } else {
      const savedProjectName = localStorage.getItem('projectName');
      if (savedProjectName) {
        setProjectName(savedProjectName);
      }
    }

  }, [location.state]);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/project_info`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.project_page;
        setTerms(data.terms);
        setpriceList(data.price_list);
        setPrice(data.price);
        setIntro(data.intro);
        setProjectDownload(data.project_download);

        setdrop_sizes(data.price?.filter(p => p.project_name == localStorage.getItem('projectName'))?.map((p) => (
          {
            size: p.size,
            title: `${p.size} Sq.Ft Amount ${p.registration_amount}`,
          }
        )))
        setLoading(false);

      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, []);

  const [columnVisibility, setColumnVisibility] = useState({
    size: true,
    rate: true,
    registration_amount: true,
    allotment: true,
    plc: true,
    oc: true,
    other_charges: true,
    total_cost: true,
    apply: true
  });

  useEffect(() => {
    if (price.length > 0) {
      const firstRow = price.find(item => item.project_name === projectName);
      if (firstRow) {
        const visibility = {};
        Object.keys(firstRow).forEach(column => {
          visibility[column] = firstRow[column] !== null;
        });
        setColumnVisibility(visibility);
      }
    }
  }, [price, projectName]);


  return (
    <>

      {loading && <Loader />}
      <Navbar />
      <div className="projdetails" style={{ minHeight: "100vh" }}>
        <div className="text-center mt-5">
          <h2 className="fw-bold text-capitalize ">{projectName}</h2>
          <div className="line mt-4 mb-5 mx-auto"></div>
        </div>
        {into.filter(term => term.project_name === projectName).length > 0 && (
          <section className="pb-5">
            <div className="container-box">
              {into
                .filter(term => term.project_name === projectName)
                .map((term, index) => {
                  return (
                    <div key={index} className="table-responsive">
                      <div className="project_details" dangerouslySetInnerHTML={{ __html: term?.description?.replaceAll(/ style="[^"]*"/g, '') }} />
                    </div>
                  )
                })}
            </div>
          </section>
        )}

        {price.filter(item => item.project_name === projectName).length > 0 && (
          <section className="py-5 bg-light-blue" id="pricelist">
            <div className="container-box">
              <h2 className="fw-bold mb-4">Price List & Area</h2>

              <div className="table-responsive">
                <table
                  className="table table-bordered border-secondary text-center"
                  style={{ verticalAlign: "middle" }}
                >
                  <thead>
                    <tr>
                      <th> S.No</th>
                      <th> Floor</th>
                      {columnVisibility['size'] && <th> Size (Sq Ft)</th>}
                      {columnVisibility['rate'] && <th> Rate Per Sq Ft</th>}
                      {columnVisibility['registration_amount'] && <th> Registration Amount</th>}
                      {columnVisibility['allotment'] && <th> Within 7 days of allotment 10% of BSP</th>}
                      {columnVisibility['plc'] && <th>
                        {" "}
                        {
                          projectName == "Bazaar 89" ? 'Within 30 Days (40% of BSP) + 50% EDC/IDC + 100% PLC if applicable'
                            : "Within 30 Days (65% of BSP) + 75% EDC/IDC + 100% PLC if applicable"
                        }
                      </th>
                      }
                      {columnVisibility['oc'] &&
                        <th>
                          {" "}
                          {/* On Apply of OC 55% of BSP + 60% EDC/IDC */}
                          Within 60 Days 10% of BSP
                        </th>
                      }
                      {columnVisibility['other_charges'] &&
                        <th>
                          {" "}
                          {
                            projectName == "Bazaar 89" ? 'On offer of possession 40% of BSP + 50% EDC/IDC + Other Charges'
                              : "On offer of possession 25% of BSP + 25% EDC/IDC + Other Charges"
                          }

                        </th>
                      }
                      {columnVisibility['total_cost'] && <th> Total Cost </th>}
                      <th> Apply </th>
                    </tr>
                  </thead>
                  <tbody>
                    {price
                      .filter(item => item.project_name === projectName)
                      .map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.title ? item.title : '--'}</td>
                          {columnVisibility['size'] && <td>{item.size ? item.size : '--'}</td>}
                          {columnVisibility['rate'] && <td>{item.rate}</td>}
                          {columnVisibility['registration_amount'] && <td>{item.registration_amount}</td>}
                          {columnVisibility['allotment'] && <td>{item.allotment}</td>}
                          {columnVisibility['plc'] && <td>{item.plc}</td>}
                          {columnVisibility['oc'] && <td>{item.oc ? item.oc : '--'}</td>}
                          {columnVisibility['other_charges'] && <td>{item.other_charges}</td>}
                          {columnVisibility['total_cost'] && <td>{item.total_cost}</td>}
                          <td>
                            {item.status === 0 ? (
                              <div
                                className="text-decoration-none text-orange"
                                onClick={() => swal("Registration Closed", "", "warning")}
                                style={{ cursor: 'pointer' }}
                              >
                                Registration Closed
                              </div>
                            ) : (
                              <Link
                                to={`${Config.appUrl}register`}
                                state={{
                                  index: 0,
                                  size: `${item.size} Sq.Ft Amount ${item.registration_amount}`,
                                  project: projectName, sizes: drop_sizes
                                }}
                                className="text-decoration-none text-orange"
                              >
                                Registration Open
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        )}


        {project_download.filter(item => item.project_name === projectName).length > 0 && (
          <section className="py-5">
            <div className="container-box py-md-4">
              <h2 className="fw-bold mb-4">Downloads Section</h2>
              <div className="table-responsive">
                <table className="table w-100  table-bordered">
                  <thead>
                    <tr>
                      <th className="col-1 text-center">Sr No.</th>
                      <th>Document Type</th>
                      <th className="text-center">Status</th>
                      <th className="col-2 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project_download
                      .filter(item => item.project_name === projectName)
                      .map((item, index) => (
                        <tr key={index}>
                          <td className="h-auto text-center">{index + 1}</td>
                          <td className="h-auto text-capitalize">{item.document_name}</td>
                          <td className="h-auto text-capitalize text-center">Approved</td>
                          <td className="h-auto text-center">
                            <a
                              href={`${Config.docUrl}/${item.download_pdf}`}
                              target="_blank"
                              rel="noreferrer"
                              className="btn btn-sm btn-success btn-width"
                              download={true}
                            >
                              <i className="bi bi-download me-2"></i>
                              Download
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        )}


        {terms.filter(term => term.project_name === projectName).length > 0 && (
          <section className="py-5 bg-light-blue">
            <div className="container-box">
              <div className="row align-items-center justify-content-between">
                <div className="col-md-8">
                  <h2 className="fw-bold mb-4">Terms & Condition</h2>
                  <div>
                    {terms
                      .filter(term => term.project_name === projectName)
                      .map((term, index) => (
                        <div key={index} dangerouslySetInnerHTML={{ __html: term.description }} />
                      ))}
                  </div>
                </div>
                {/* <div className="col-md-2">
          <a href="https://www.mrgworld.com/application-form.php?project=bazaar89" target='_blank'>
            <img src={qr89} alt="" className="w-100 " />
          </a>
        </div> */}
              </div>
            </div>
          </section>
        )}
      </div>

      <footer style={{ backgroundColor: "#155995" }}>
        <div className="py-4 text-white text-center px-4">
          <span>
            {" "}
            Copyrights © {new Date().getFullYear()} Haryana Approved Shops
            Gurugram - All rights reserved.
          </span>
        </div>
      </footer>

    </>
  );
};




export default Swarnim1;
