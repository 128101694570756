import React from "react";
import Carousel from "react-bootstrap/Carousel";

import { Config } from "../Config";

const ImageSlider = ({ data }) => {
  return (
    <div>
      <Carousel>
        {data.images.map((slider, index) => (
          <Carousel.Item key={index}>
            <a href={`${Config.docUrl}${slider.button_url}`} download target="_blank" 
 rel="noreferrer">
              <img src={`${Config.imageUrl}/sliders/${slider.image}`} className="w-100 slider-image" alt="" />
            </a>
          </Carousel.Item>
        ))}

      </Carousel>
    </div>
  );
};

export default ImageSlider;
