import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "./Components/Index";
import Contact from "./Components/Contact";
import RegisterForm from "./Components/RegisterForm";
import Swarnim1 from "./Components/Sectors/Swarnim1";
import AllSectors from "./Components/AllSectors";
// import bazaarFirst from "./Components/Bazaar/BazaarFirst";
// import bazaarSec from "./Components/Bazaar/BazaarSec";
// import bazaarThird from "./Components/Bazaar/BazaarThird";
import SwarnimFirst from "./Components/Swarnim/SwarnimFirst";
import SwarnimSec from "./Components/Swarnim/SwarnimSec";
import { Config } from "./Config";
import AffordablePolicy from "./Components/Swarnim/AffordablePolicy";

function App() {
  return (

    <div className="">
      <Router>
        <ToastContainer theme="colored" />
        <Routes>
          <Route path={`${Config.appUrl}`} Component={Index} />
          <Route path={`${Config.appUrl}get-in-touch`} Component={Contact} />

          <Route path={`${Config.appUrl}all-projects`} Component={AllSectors} />
          <Route path={`${Config.appUrl}projectdetails/:projectname`} Component={Swarnim1} />

          <Route path={`${Config.appUrl}register`} Component={RegisterForm} />

          <Route path={`${Config.appUrl}refund-policy/:policyName`} Component={SwarnimFirst} />
          <Route path={`${Config.appUrl}affordable/:policyName`} Component={AffordablePolicy} />

          <Route path={`${Config.appUrl}refund-prolicy-swarnim-ii`} Component={SwarnimSec} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;







































{/* <Route path={`${Config.appUrl}refund-prolicy-bazaar-89`} Component={bazaarFirst} />
          <Route path={`${Config.appUrl}refund-prolicy-bazaar-90`} Component={bazaarSec} />
          <Route path={`${Config.appUrl}refund-prolicy-bazaar-93`} Component={bazaarThird} /> */}
