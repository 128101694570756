import React from "react"
import "../assets/css/Loader.css"

const Loader = () => {

  return (
    // <div className="spinner-loader">
    //   <div className="lds-ellipsis">
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //     <div></div>
    //   </div>

    // </div>

    <div className="preloader">
        <div className="spinner_wrap d-flex">
          <div className="spinner mx-2" />
          <div className="spinner mx-2" />
          <div className="spinner mx-2" />
        </div>
      </div>
  )
}

export default Loader
