import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useLocation } from "react-router";
import axios from "axios";
import swal from "sweetalert";

import { Config } from "../Config";
import Loader from "./Loader";

const RegisterForm = () => {

  const location = useLocation();
  const drop_sizes = location?.state?.sizes

  const [applicationName, setApplicationName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [refrence, setRefrence] = useState("");
  const [aadharCard, setAadharCard] = useState("");
  const [panCard, setPanCard] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");
  const [quota, setQuota] = useState("");
  const [size, setSize] = useState(location?.state?.size);
  const [stateDrp, setStateDrp] = useState([]);
  const [cityDrp, setCityDrp] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [loading, setLoading] = useState(false);

  const [terms, setTerms] = useState(false);

  const [errors, setErrors] = useState({
    applicationName: "",
    fatherName: "",
    dateOfBirth: "",
    phoneNumber: "",
    emailAddress: "",
    aadharCard: "",
    panCard: "",
    city: "",
    state: "",
    pincode: "",
    address: "",
    quota: "",
    size: "",
    terms: "",
  });

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  // window close functionality =========================================
  useEffect(() => {

    // Assign event listener directly to window.onbeforeunload
    window.onbeforeunload = handleBeforeUnload;

    // Cleanup function to remove the event listener
    return () => {
      window.onbeforeunload = null; // Remove event listener
    };
  }, []);

  const handleBeforeUnload = (event) => {
    // Show custom popup warning only if the form is not submitted
    const message = 'Your data will be lost if you don\'t save it!';
    event.preventDefault();
    event.returnValue = 'Your data will be lost if you don\'t save it!'; // Necessary for Chrome
  };

  useEffect(() => {
    axios.get(`${Config.apiUrl}/state`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.state;

        const filteredOptions = data.filter(option => option.country_id === 1);

        setStateDrp(filteredOptions);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);

      });
  }, []);

  useEffect(() => {
    if (selectedState) {

      const formData = new FormData();
      formData.append("state_id", selectedState);

      axios.post(`${Config.apiUrl}/city`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          const data = response.data;
          setCityDrp(data.city);
        })
        .catch(error => {
          console.error('There was a problem fetching the city data:', error);
        });
    }
  }, [selectedState]);

  // const payLinks = [
  //   "https://smartpay.easebuzz.in/101948/SwarnimI",
  //   "https://smartpay.easebuzz.in/102223/SwarnimII"
  // ];

  // const QrCodes = [swarnimQr1, swarnimQr2]


  // validations for form
  const handleValidation = () => {
    const newErrors = {};

    if (!applicationName) {
      newErrors.applicationName = "Applicant Name is required";
    }

    if (!fatherName) {
      newErrors.fatherName = "Father Or Husband Name is required";
    }

    if (!dateOfBirth) {
      newErrors.dateOfBirth = "DOB is required";
    }

    if (!aadharCard) {
      newErrors.aadharCard = "Aadhar no. is required";
    } else if (aadharCard.length !== 12) {
      newErrors.aadharCard = "Aadhar no. contains 12 digits";
    }

    if (!panCard) {
      newErrors.panCard = "Pan no. is required";
    } else if (!(/^[A-Z]{5}\d{4}[A-Z]$/).test(panCard)) {
      newErrors.panCard = "Please Enter Valid Pan Number";
    }

    if (!city) {
      newErrors.city = "City is required";
    }

    if (!state) {
      newErrors.state = "State is required";
    }

    if (!pincode) {
      newErrors.pincode = "Pincode is required";
    }

    if (!quota) {
      newErrors.quota = "Quota is required";
    }

    if (!size) {
      newErrors.size = "Size is required";
    }

    if (!refrence) {
      newErrors.refrence = "Refrence By is required";
    }

    if (!address) {
      newErrors.address = "Address is required";
    }

    if (!emailAddress) {
      newErrors.emailAddress = "Email is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)
    ) {
      newErrors.emailAddress = "Invalid email format";
    }

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (phoneNumber.length > 10 || phoneNumber.length < 10) {
      newErrors.phoneNumber = "Phone number must be of 10 digits";
    }

    if (!terms) {
      newErrors.terms = "Please Check to agree terms and conditions";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // ==========================

  const [payment_link, setPaymentLink] = useState('')
  const [qr_image, setQrImage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("application_name", applicationName);
        formData.append("fname", fatherName);
        formData.append("dob", dateOfBirth);
        formData.append("phone", phoneNumber);
        formData.append("email", emailAddress);
        formData.append("aadhar", aadharCard);
        formData.append("pan", panCard);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("pincode", pincode);
        formData.append("address", address);
        formData.append("quota", quota);
        formData.append("size", size);
        formData.append("refrence_by", refrence);
        formData.append("count", localStorage.getItem('projectName'));

        const res = await axios.post(
          `${Config.apiUrl}/register`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.data.message == "Message Succesfully sent...") {
          window.onbeforeunload = null
          swal("", "Your Application has been sent successfully!", "success").then(() => {
            window.location.href = payment_link;
          });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        swal("", error.response.data.message, "error");
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    // Get the value of the key pressed
    const keyValue = e.key;
    // Check if the key pressed is a numeric character or a control key (like backspace)
    if (!/\d/.test(keyValue) && keyValue !== "Backspace" && keyValue !== "Delete" && keyValue !== "ArrowLeft" && keyValue !== "ArrowRight" && keyValue !== "Tab") {
      e.preventDefault(); // Prevent the default action (typing the character)
    }
  };

  const [back_img, setBackImage] = useState('')

  useEffect(() => {
    setLoading(true)
    axios.get(`${Config.apiUrl}/homepage`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Content-Disposition': 'attachment'
        }
      })
      .then(response => {
        const sortedArray = response?.data?.home_page?.gallery_section?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setBackImage(sortedArray?.slice(0, 1)[0]?.image)
        setLoading(false);

        const link = response?.data?.home_page?.project_info?.filter((obj) => obj.project_name == localStorage.getItem('projectName'))
        setPaymentLink(link[0]?.register_link)

        setQrImage(response?.data?.home_page?.qrcode.filter(item => item.project_name == localStorage.getItem('projectName'))[0]?.image)
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      })
  }, [])

  return (
    <>
      {loading && <Loader />}
      <Navbar />

      <section className=" py-5 bg-contact" style={{ backgroundColor: '#135a941b', backgroundImage: `url(${Config.imageUrl}/sliders/${back_img})` }}>
        <div className="container-box py-md-4">
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-around">
              <div className="col-md-8 bg-white rounded-4 py-5 px-sm-5 px-4">
                <h4
                  className="text-blue mb-4 lh-sm"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  Registration for Affordable Commercial Shops Under Housing
                  Policy 2013, Government Of Haryana{" "}
                </h4>

                <div className="line mb-4"></div>
                <div className="row ">
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Applicant Name <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={applicationName}
                        onChange={(e) => setApplicationName(e.target.value)}
                        placeholder="Enter Applicant Name"
                        className="form-field border border-2"
                      />
                      {errors.applicationName && (
                        <span className="text-danger">
                          {errors.applicationName}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Applicant Father/Husband Name <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={fatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                        placeholder="Enter Applicant Father/Husband Name"
                        className="form-field border border-2"
                      />
                      {errors.fatherName && (
                        <span className="text-danger">{errors.fatherName}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Date Of Birth <span className="text-red">*</span>
                      </label>
                      <input
                        type="date"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        className="form-field border border-2"
                      />
                      {errors.dateOfBirth && (
                        <span className="text-danger">
                          {errors.dateOfBirth}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Phone Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={phoneNumber}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter Phone Number"
                        className="form-field border border-2"
                      />
                      {errors.phoneNumber && (
                        <span className="text-danger">
                          {errors.phoneNumber}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Email Address <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                        placeholder="Enter Email Address"
                        className="form-field border border-2"
                      />
                      {errors.emailAddress && (
                        <span className="text-danger">
                          {errors.emailAddress}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Reference By <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={refrence}
                        onChange={(e) => setRefrence(e.target.value)}
                        placeholder="Reference By"
                        className="form-field border border-2"
                      />
                      {errors.refrence && (
                        <span className="text-danger">
                          {errors.refrence}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Aadhar Card Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={aadharCard}
                        onChange={(e) => setAadharCard(e.target.value)}
                        placeholder="Enter Aadhar Card Number"
                        onKeyPress={handleKeyPress}
                        className="form-field border border-2"
                      />
                      {errors.aadharCard && (
                        <span className="text-danger">{errors.aadharCard}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Pan Card Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={panCard}
                        onChange={(e) => setPanCard(e.target.value)}
                        placeholder="Enter Pan Card Number"
                        className="form-field border border-2"
                      />
                      {errors.panCard && (
                        <span className="text-danger">{errors.panCard}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Address <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Enter Address"
                        className="form-field border border-2"
                      />
                      {errors.address && (
                        <span className="text-danger">{errors.address}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        State <span className="text-red">*</span>
                      </label>
                      <select
                        value={selectedState}
                        onChange={(e) => {
                          const selectedStateName = e.target.options[e.target.selectedIndex].text;
                          setState(selectedStateName);
                          setSelectedState(e.target.value);
                        }}
                        className="form-select border border-2"
                      >
                        <option value="">Select State</option>
                        {stateDrp.map((option, index) => (
                          <option key={index} value={option.id}>{option.name}</option>
                        ))}
                      </select>
                      {errors.state && (
                        <span className="text-danger">{errors.state}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        City <span className="text-red">*</span>
                      </label>
                      <select
                        value={selectedCity}
                        onChange={(e) => {
                          const selectedStateName = e.target.options[e.target.selectedIndex].text;
                          setCity(selectedStateName);
                          setSelectedCity(e.target.value);
                        }}
                        className="form-select border border-2"
                      >
                        <option value="">Select City</option>
                        {cityDrp.map((option, index) => (
                          <option key={index} value={option.id}>{option.name}</option>
                        ))}
                      </select>
                      {errors.city && (
                        <span className="text-danger">{errors.city}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Pin code <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        value={pincode}
                        onChange={(e) => setPincode(e.target.value)}
                        placeholder="Enter Pin code"
                        onKeyPress={handleKeyPress}
                        className="form-field border border-2"
                      />
                      {errors.pincode && (
                        <span className="text-danger">{errors.pincode}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Quota <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-field bg-white border border-2"
                        style={{ height: 48 }}
                        value={quota}
                        onChange={(e) => setQuota(e.target.value)}
                      >
                        <option value="">-- Select Quota --</option>
                        <option value="Govt Employee Quota">
                          Govt Employee Quota
                        </option>
                        <option value="Female Quota">Female Quota</option>
                        <option value="General Quota">General Quota</option>
                        <option value="Management Quota">
                          Management Quota
                        </option>
                      </select>
                      {errors.quota && (
                        <span className="text-danger">{errors.quota}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <div>
                      <label htmlFor="" className="text-dark">
                        Select Size <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-field bg-white border border-2"
                        style={{ height: 48 }}
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                      >
                        <option value="">-- Select Size --</option>
                        {drop_sizes?.map(
                          (size) => {
                            return (
                              <option key={size.size} value={size.title}>
                                {size.title}
                              </option>
                            );
                          }
                        )}
                      </select>
                      {errors.size && (
                        <span className="text-danger">{errors.size}</span>
                      )}
                    </div>
                  </div>

                  <div>
                    <input
                      type="checkbox"
                      id="terms"
                      onChange={(e) => setTerms(e.target.checked)}
                    />
                    <label
                      htmlFor="terms"
                      className="text-dark ms-3 "
                      style={{ fontSize: "14px" }}
                    >
                      By clicking checkbox, you agree to the terms and
                      conditions set out by this site. <span className="text-red">*</span>
                    </label>
                    {errors.terms && (
                      <p className="text-danger">{errors.terms}</p>
                    )}
                  </div>

                  <div className="mt-4">
                    <button className="button">
                      {loading ? "Submitting data ..." : "Submit & Pay"}
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-3 col-8 mt-md-0 mt-5">
                <img
                  src={`${Config.imageUrl}/sliders/${qr_image}`}
                  alt="qr code"
                  className="w-100 qr-image"
                />
              </div>
            </div>
          </form>
        </div>
      </section>

      <footer style={{ backgroundColor: "#155995" }}>
        <div className="py-4 text-white text-center px-4">
          <span>
            {" "}
            Copyrights © {new Date().getFullYear()} Haryana Approved Shops
            Gurugram - All rights reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default RegisterForm;
