import React, { useEffect } from "react";
import Navbar from "../Navbar";
import "../../assets/css/bazaar.css";

const SwarnimSec = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className="page-content pb-5">
        <section className="py-5">
          <div className="container-box py-md-2">
            <div className="text-center">
              <h2 className="fw-bold text-capitalize ">Terms & Conditions</h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>
            <div className="container">
              {/* <h3>
                TERMS AND CONDITIONS FOR REGISTRATION FOR PROVISIONAL ALLOTMENT OF UNIT
                IN THE COMMERCIAL COMPLEX GURGAON SECTOR 90 
              </h3> */}
              {/* <p>Please Read Booking Terms and Privacy Policy </p> */}
              <h3>
                TERMS AND CONDITIONS FOR REGISTRATION FOR PROVISIONAL ALLOTMENT OF UNIT IN THE COMMERCIAL COMPLEX SECTOR 5, SOHNA, GURUGRAM.
              </h3>
              <p>
                The following terms and conditions are determined for the purpose of provisional allotment. However further the terms and conditions shall be more comprehensively and elaborately set out in the agreement to be executed between the Applicant and the Developer (The “Builder Buyers Agreement”).
              </p>
              <p>
                This is an application for provisional booking for a Commercial Shop/Unit proposed to be developed by “M/s. Desi Construction Pvt Ltd.” under its residential Housing colony Known as “TATHASTU II”, on a plot measuring 8.975 Acres of land at Sector 5, Sohna, Gurugram, Haryana (the “Land”).
              </p>
              <p>
                The Applicant(s) have applied for allotment of aforesaid Commercial Shop/Unit with full knowledge and subject to all the laws/notifications(s) and rules applicable in the general, which have been explained by the Developer/LLP to the Applicant(s) in vernacular language and duly understood by the Applicant(s).
              </p>
              <p>
                The Applicant(s) has examined and fully satisfied himself/herself/themselves about the rights, interest and the title of the Developer in the project land owned by the Developer on which the Project will be developed and the applicant has understood all limitations and obligations in respect thereof. And there will be no more investigations of objection by the Applicant(s) in this respect. The Applicant agrees and accepts to abide by the terms and conditions of all the permissions, sanctions, directions etc. issued by DTCP and/or by any other competent authorities in this regard to the Developer.
              </p>
              <p>
                The Applicant(s) has inspected the site where the Project is proposed to be constructed. The Applicant(s) has not merely relied or been influenced by any architect’s plan, sales plan, sales brochures, advertisement, representations, warrantees, statements or estimates of any nature whatsoever whether written or oral made by the Developer/LLP and has taken his/her/their personal judgement prior to booking the Commercial Shop/Unit.
              </p>
              <p>
                The Applicant(s) has inspected the site where the Project is proposed to be constructed. The Applicant(s) has not merely relied or been influenced by any architect’s plan, sales plan, sales brochures, advertisement, representations, warrantees, statements or estimates of any nature whatsoever whether written or oral made by the Developer/LLP and has taken his/her/their personal judgement prior to booking the Commercial Shop/Unit.
              </p>
              <p>
                The Applicant(s) has inspected the site where the Project is proposed to be constructed. The Applicant(s) has not merely relied or been influenced by any architect’s plan, sales plan, sales brochures, advertisement, representations, warrantees, statements or estimates of any nature whatsoever whether written or oral made by the Developer/LLP and has taken his/her/their personal judgement prior to booking the Commercial Shop/Unit.
              </p>
              <p>
                That the allotment of Unit shall be subject to the terms and conditions imposed by the competent authorities including the Director, Town & Country Planning, Haryana while granting permission for development of the Commercial Complex on the land and / or at any time thereafter. The Commercial Shop/Unit shall also be governed by all the laws/notifications and rules applicable to the area and/or Municipal Committee, Gurgaon, Haryana including the terms and conditions as contained herein.
              </p>
              <p>
                The Applicant(s) (Successful Allottee) shall have a right in the Common Areas as provided under Rule 2(1)(f) of HRERA Rules, 2017 of the State and shall be entitled to use common areas as permissible under the applicable law and right in the common areas as per provisions of HDRU Act, 1975 (8 if 1975).
              </p>
              <p>
                Only such applications shall be considered which are complete and fulfil the terms and conditions for allotment of Commercial Shop/Unit. However, it is possible that some of the application forms have certain minor deficiencies viz., missing entry on the application form, incorrect/missing line in affidavit, illegible copies of certain documents. Applicants may be granted an opportunity of removing the shortcomings in their application in all respects within a period of 15 days, failing which their claim stand forfeited.
              </p>
              <p>
                The Builder Buyer Agreement shall be executed by the Developer after realization of money due on allotment along with applicable taxes etc. However, issuance of Allotment Letter/Builder-Buyer Agreement shall not confer any claim / right to the Applicant(s) until all the terms and conditions of Application Form and/or Allotment Letter/Builder Buyer Agreement are fulfilled and complied by the Applicant(s), failing which this provisional booking shall be cancelled.
              </p>
              <p>
                The Applicant(s) has seen the zoning plan, layout plan, sanction plan, Building Plan, specifications, amenities etc. and has been made aware of and accepts that the zoning plan, layout plan, Building Plan of Commercial Shop/Unit are tentative and that there may be minor variations/alterations either due to architectural and structural reasons or pursuant to requirements of a Governmental Authority, which may involve changes, including change in the zoning plans for the Project, and nature of facilities to be provided in the Project in accordance with the policy. The Applicant understand that the Developer reserves its right to effect suitable changes and alterations in the Layout Plan, building core, lobbies, common areas, service areas, elevations, massing, specifications, features, height, width, finishing (internal and external), materials to be used, electricity load, power etc., of the Project at any time in any manner it thinks fit and proper for the betterment of the commercial structure.
              </p>
              <p>
                That the Applicant further understands and agree that although every attempt shall be made by the Developer to adhere to the size, location, product mix and super/Carpet are of the Unit, however in the event that there is any change in the Unit’s location or product mix or variation in its size to the extent of + 5% at the time of final measurement, the Applicant shall accept the same and shall make the payment of the Sale Consideration, the applicable PLC, other charges and applicable taxes as agreed herein in proportion, as the case may be, either payable or refundable, to such variation without any interest thereon and no other claim, whatsoever monetary or otherwise shall exist against the Developer in any manner whatsoever by the Application. If required by the developer, the applicant will be bound to issue an affidavit or no objection or declarations in approval of such modifications, alterations etc.
              </p>
              <p>
                hat the Developer shall have the absolute right to make additional construction anywhere in the Project Including adjoining and/or in the vicinity of the said Shop/unit, whether on account of increase in Floor Area Ratio (FAR) or better utilization of the said land or for any other reason whatsoever to the extent permissible by the Government or Competent Authorities. The Developer shall have the absolute right to transfer such additionally constructed areas/units/Shops in any manner whatsoever as the Developer may in its absolute discretion deem fit. The Developer and the transferees of such additional construction shall have the same rights as the Applicant with respect to the Project including the right to be member of the Society/Association of Unit Owners to be formed and an equal right to use of the common areas and other common amenities of the Project. The applicant acknowledges that the applicant shall have no objection to the same and the applicant will not claim will not claim any adverse rights to such constructions.
              </p>
              <p>
                The Applicant agrees and understands that the price of the Commercial Shop/Unit shall be based on the price of material and labour charges pertaining thereon on the date when the excavation work at the Project site commences, which date shall be duly communicated to the Applicant if, however, during the progress of construction up to the expiry of 36 months or 42 months (including grace period), as the case may be , from the above mentioned date, there is an increase/decrease in the price of the materials used in the construction work and/or labour charges (hereinafter referred to as “Escalation Charges”), the same shall be recoverable/payable respectively from/to the Applicant/Allottee as per the rules and regulations issued by the competent authority. The Developer shall appoint a reputed firm of Chartered Accountants to independently audit and verify the computation of escalation Charges done by the Developer from time to time. Such audited and verified Escalation Charges shall be paid/refunded (or adjusted), as the case may be, by/to the Applicant /Allottee shall be final and binding on the Applicant/Allottee. The Applicant/Allottee agrees and understands that any default in payment of the Escalation Charges shall be Deemed to be a breach under the terms and conditions of the Application / Builder Buyers Agreement. No Possession shall be handed over to the Applicant/Allottee unless Escalation Charges are paid in full along with delayed interest, if any.
              </p>
              <p>
                The Applicant, on becoming an allottee, shall be liable to pay the price for the Commercial Shop/Unit base on its Carpet Area/Super Area equivalent to Rs… (Rupees only) (“Total Price”) as detailed in Annexure I.
              </p>
              <p>
                In addition to the above consideration, The Allottee shall be required to pay Interest Free Maintenance Security Deposit Charges as mutually decided. The Developer shall periodically intimate in writing to the Applicant (successful allottee) the amount payable as stated above in accordance with payment plan, the Applicant (successful allottee) shall make payment as demanded by the Developer within the time and in the manner specified therein. In addition, the Developer shall provide to the Applicant (successful allottee) the details of the new taxes/fees/charges/levies etc. paid or demanded along with copy of new amendment in the acts / rules / notifications together with dates from which such taxes /fess/charges levies etc. have been imposed or become effective.
                In case there is any change or modification in the rate of any applicable taxes/fees/charges/levies etc. the subsequent amount payable by the Applicant (successful allottee) to the Developer shall be increased or decreased on such change or modification.
              </p>
              <p>
                Provided further that GST is applicable on interest, late fees and penalty on delayed payment. Pursuant to foregoing, interest, late fees and penalty on delayed payment along with GST applicable thereon will be computed and to be payable as and when the Applicant (successful allottee) will make such payments to the Developers against these charges. Provided further that if there is any increase in the rate of taxes/fees/charges/levies etc. after the expiry of the scheduled date of completion of the Project as per registration granted by the competent authority, which shall also include the extension of registration granted to the Project, if any, as per the Real Estate Act, the same shall not be charged from the Applicant (successful allottee).
              </p>
              <p>

                The total price includes the Booking amount paid by the applicant (successful allottee) to the Developer towards the aforesaid Commercial Shop/Unit based on its Carpet area/Super Area.
              </p>
              <p>
                The applicant (successful allottee(s)) will be required to pay the balance amount as per Payment Plan. In case of re-allotment, amount due form original allottee till the date of reallotment will be payable by the new allottee. Subsequent instalment will be payable as per payment plan applicable to the original allottee. Any default in payment will bear penal interest as provided in rule 15 of the Haryana Real Estate Regulatory Authority, Rule, 2017.
              </p>
              <p>
                All payments shall be made through DEMAND DRAFT/CHEQUES/NEFT/RTGS ECT. In favour of “DCPL TATHASTU II COLLECTION A/C 739005000405”. The applicant (successful allottee(s)) must specify their name, address, application no., customer ID, mobile no., Project name and Unit no. on the back side of cheque/Demand draft accepted by the LLP/Developer and the LLP/Developer shall be deemed to have accepted such cheque/demand draft subject to their realization.
              </p>
              <p>
                All payments should be deposited only at the office of the Developer. The Developer shall not be responsible / accountable for any payment made to agent/broker/any third person. The Applicant(s) must insist for duly signed receipt form the authorized personnel of the Developer.
              </p>
              <p>
                In case of delayed instalments/any other dues by the Applicant(s),the applicant (Successful Allottee) shall be liable to pay interest as provided in Rule 15 of the Haryana Real Estate Regulatory Authority Rules, 2017. The Applicant (successful allottee) before taking possession of the Commercial Shop/Unit shall clear the dues towards the said Shop/Unit.
              </p>
              <p>
                The Applicant (successful allottee) before taking possession of the Commercial Shop/Unit shall clear the dues towards the said Shop/Unit.
              </p>
              <p>
                The cost of electric meter and initial electricity connection charges (i.e. proportionate cost including man power and admin cost for establishing the sub-station, Load Sanction fees, Cost of HT cable, Switching Station, Equipment, Transformer etc.) are to be paid by allottee separately at the time of handing over the possession of the Commercial Space/Unit. The Applicant(s) shall sign and execute all papers, documents, agreements for purpose of obtaining electricity power back-up facility and or any other service. The Applicant(s) shall additionally pay on demand to the Developer his proportionate share of the cost provision of electric standby generator.
              </p>
              <p>
                The total price also includes the External Development Charges/IDC, Other Charges and Taxes, as applicable, as per applicable laws.
              </p>
              <p>
                The cost of electric meter and initial electricity connection charges (i.e. proportionate cost including man power and admin cost for establishing the sub-station, Load Sanction fees, Cost of HT cable, Switching Station, Equipment, Transformer etc.) are to be paid by allottee separately at the time of handing over the possession of the Commercial Space/Unit. The Applicant(s) shall sign and execute all papers, documents, agreements for purpose of obtaining electricity power back-up facility and or any other service. The Applicant(s) shall additionally pay on demand to the Developer his proportionate share of the cost provision of electric standby generator.
              </p>
              <p>
                The Developer shall not be responsible towards any third party, who has made payments, remittances to the Developer on behalf of the Applicant and such third party shall have no right in the Said Unit. The Developer shall issue the payment receipt only in favour of the Applicant.
                if the applicant (successful allottee) fails to make payments for two consecutive demands made by the Developer as per the Payment Plan opted by applicant, despite having been issued notice in that regard the allottee shall be liable to pay interest to the Developer on the unpaid amount at the rate prescribed in the Rules;
              </p>
              <p>
                In case of Default by Allottee under the conditions listed above continues for a period of ninety days after notice form the Developer in this regard, The Developer may cancel the allotment of the Commercial Shop/Unit in favour of the Allottee and refund the money paid by the allottee by forfeiting the earnest money (which is 10% of the total cost of unit) and interest component on delayed payment (payable by the customer for breach of agreement and non-payment of dues payable to the Developer). The rate of interest payable by the allottee to the Developer shall be the State Bank of India highest marginal cost of lending rate plus two percent. The balance amount of money paid by the allottee shall be returned by the Developer to the allottee within ninety days of such cancellation. On such default, the Agreement and any liability of the promoter arising out of the same shall thereupon, stand terminated. Provided that, the Developer shall intimate the allottee about such termination at least thirty days prior to such termination and will refund in the name of first allottee only.
              </p>
              <p>
                The Developer shall also be entitled to and hereby reserves its right to cancel the allotment/terminate this Application/Builder Buyers Agreement in the manner described above, if in the opinion of the Developer, (a) the allotment of the Unit has been obtained through misrepresentation and concealment or suppression of any material fact, OR(b) the Applicant has violated or violates any of the directions issued, rules and regulations framed by the Developer or the Maintenance Agency or by any statutory body or Competent Authority(ies)/Department(s).
              </p>
              <p>
                The Applicant shall indemnify and undertakes to keep the Developer, its directors , representatives, assignees and nominees, saved indemnified and harmless from and against all losses, damages, liabilities and consequences resulting from non-payment, non- observance or breach by the Applicant of any law or representations, covenants, conditions, warranties and undertakings of the Applicant.
              </p>
              <p>
                That the Applicant understands and agrees that it shall not alienate/nominate/transfer/assign its right/allotment in favour of any third party before the expiry of 12 months from the realization of the booking money paid by the Applicant and no outstanding dues against the said unit should exist on that date. Prior information and confirmation from the Developer is compulsory, however the Developer may at its sole discretion permit the transfer/assignment, subject to payment of transfer charges as may be decided by the Developer from time to time and subject to compliance by the Applicant of the applicable laws, rules, regulations and the directions of the Developer, Government, Competent Authorities etc. including the liability for payment of tax, penalty or duties etc., or as the case may be. The Transferee shall adhere to the terms and conditions of this application and the Builder Buyers Agreement. In case of non-compliance by transferee, the applicant shall indemnify the developer for losses suffered and the transfer will not be binding upon the developer.<br />
                The applicant(s) has fully understood and agrees that in case the Applicant(s) withdraws or surrenders his application for allotment for any reason whatsoever at any point of time, then the Developer at its sole discretion may cancel/terminate the booking/allotment/application and shall forfeit the Earnest Money (which is 10% of the total cost of Unit) and will refund the balance amount, if any, to the First Applicant only.
              </p>
              <p>
                In case of surrender/cancellation of Commercial Shop/Unit after the end of the Financial year, GST paid and/or due up to preceding financial years along with booking amount and interest due etc. as per Applicable Laws shall be deducted & the balance amount shall be refunded to the First applicant only.
              </p>
              <p>
                The Applicant(s) shall have no objection in case the Developer creates a charge on the Project Land during the course of development of the Project for raising loan from any bank/financial institution. However, such charge, if created, shall be vacated before handling over possession of the Commercial Shop/Unit to the Applicant (successful allottee).
              </p>


              <p>
                The Applicant (successful allottee) may avail loans from financial institutions to finance the Commercial Shop/Unit. However, if a particular financing institution or bank refuses to extend financial assistance on any ground, the Applicant (successful allottee) shall not make such refusal an excuse for non-payment of further instalments/dues. In case there is delay in processing the loan in favour of the Applicant (successful allottee) due to any reason whatsoever and consequently the payments of instalments are delayed by the Applicant (successful allottee) to the Developer, the Applicant (successful allottee) agrees and to make the payment of accrued interest to the Development, unconditionally.
              </p>
              <p>
                The Applicant shall, before taking possession of the Commercial Shop / Unit, clear the dues towards the Commercial Shop/Unit and have the conveyance/Sale deed for the Commercial Shop/Unit executed in his/her favour by paying stamp duty, registration free & administrative charges/lawyer fees for registration of sale deed and other charges/expenses, as applicable, to the concerned sub registrar office.
              </p>
              <p>
                Possession of Commercial Shop/Unit shall be offered by the Developer within a period of 5 years from the date of approval of layout plans/building plans/demarcation/zoning plan (whichever is applicable) or grant of environmental clearance, whichever is later and within such extended time (if any) as may be allowed by competent authorities.

                Upon receipt of the occupation certificate or part thereof in respect of the Project, the Developer shall issue a written notice offering the possession of the commercial Shop/Unit (“Possession Notice”) to the Applicant (successful allottee) offering the possession of the Unit
              </p>
              <p>
                The Developer from the date of grant of occupancy certificate of the project or part thereof shall hand over the maintenance to the maintenance agency. The developer will not be responsible for any maintenance. Once the RWA is constituted the engaging of agency for such maintenance works shall be at the sole discretion and the terms and conditions as finalised by the “Association of Commercial Shop Owners” constituted under the Haryana Ownership Act 1983, will be final.
              </p>
              <p>
                That applicant shall from time to time sign all applications, papers, documents, maintenance agreement, electricity agreement and other relevant papers and agreement(s), as required, in pursuance to the allotment and to do all acts, deeds and things as Developer may require in the interest of the Project and Unit. In case of joint Applicant, any document signed/accepted/acknowledged by the First Applicant shall be binding upon the other joint Applicants
              </p>
              <p>
                The applicant (successful allottee) shall bear costs of consumption of electricity and water for his Commercial Shop/Unit as well as the proportionate cost i.e. Security, House Keeping, Electricity, Water, Manpower & Consumables, Insurance, AMC & Depreciation of Plant & Machinery etc. and other costs for providing common services and facilities in the Project with effect from the date of handing over of possession by the Developer.
              </p>
              <p>
                The applicant (successful allottee) shall bear costs of consumption of electricity and water for his Commercial Shop/Unit as well as the proportionate cost i.e. Security, House Keeping, Electricity, Water, Manpower & Consumables, Insurance, AMC & Depreciation of Plant & Machinery etc. and other costs for providing common services and facilities in the Project with effect from the date of handing over of possession by the Developer.
              </p>
              <p>
                The construction/development of the Commercial Complex is subject to any event or combination of events or circumstances beyond this reasonable control of the Developer which cannot (a) by the exercise of reasonable diligence, or (b) despite the adoption of reasonable precaution and / or alternative measures, be prevented, or caused to be prevented, and which adversely affects the Developer’s ability to perform including but not limited to the following:
              </p>
              <p>
                commercial Shop/Unit (“Possession Notice”) to the Applicant (successful allottee) offering the possession of the Commercial Shop/Unit to be taken within stipulated time. Upon receiving the Possession Notice from the Developer, the Applicant (successful allottee) shall take possession of the Commercial Shop/Unit from the Developer by executing necessary indemnities, undertakings and such other documentation as prescribed in the Agreement and the Developer shall give possession of the Commercial Shop/Unit to the Applicant (successful allottee). In case the Applicant (successful allottee) fails to take possession within the time provided in the Possession Notice, such Applicant (successful allottee) shall continue to be liable to pay maintenance charges and holding charges in terms of the Agreement.
              </p>
              <p>
                That the Applicant agrees and undertakes to use the said Shop/Unit only for Commercial purpose as defined as per prevailing norms and approvals and sanctions provided by the Statutory Authorities and for no other purpose and further the applicant shall not conduct any illegal or immoral activities from or in the Shop/Unit. The Applicant further undertakes not to carry on any activity from and on the Shop/Unit, which creates nuisance or its illegal, obnoxious contrary to public policy or contrary to the common interest of the collective
              </p>
              <p>
                Act of god i.e. Fire, draught, flood, earthquake, epidemics, natural disasters; Explosions or accidents, air crashes, act of terrorism; Strikes or lock outs, industrial disputes, Lockdown;
              </p>
              <p>
                Non-availability of cement, steel or other construction/raw material due to strikes of manufacturer, supplier, and transporters or due to any reason whatsoever.
                War and hostilities of war, riots, bandh, act of terrorism or civil commotion;
              </p>
              <p>
                The promulgation of or amendment in any law, rule or regulation of the issue of any injunction, court order or direction from any governmental or statutory authority that prevents or restricts the Developer from complying with any or all the terms and conditions as agreed in the Agreement; or/

                Any legislation, order or rule or regulation make or issued by the Government Authority or if any Governmental Authority refuses, delays, withholds, denies the grant of necessary approvals/certificates for the project/Commercial Complex or if any matters, issues relating to such approvals, permissions, notices, notifications, by the Government Authority(ies) becomes subject matter of any suit/writ before a competent court or; for any reason whatsoever.
              </p>
              <p>
                Any event or circumstances analogous to the foregoing. (“Force Majeure Events”).

                owners/occupants of the Project. The applicant shall not modify or alter the basic structure of the Shop/Unit. The Applicant will not use the said Shop/Unit or common areas or parking in any manner which may be illegal or create nuisance to others.

                The Applicant shall not keep any material in the common areas of the Project. Applicant(s) shall be entitled to use the common areas of the Project along-with other allottee for such purposes for which such common areas have been developed.

                The Applicant (successful allottee) agrees and confirms that in the event it becomes impossible for the Developer to implement the Project due to Force Majeure Events and above-mentioned conditions, then this allotment shall stand terminated and the Developer shall refund to the Applicant, the entire amount received by the Developer form the Applicant within ninety days. The Developer shall intimate the Applicant (successful allottee) about such termination at least thirty days prior to such termination. After refund of the money as paid by the Applicant (successful allottee), the applicant (successful allottee) agrees that he/she shall not have any rights, claims etc. against the Developer and that the Developer shall be released and discharged from all its obligations and liabilities.
              </p>
              <p>
                Subject to the Force Majeure Events, Court Orders, Government Policy/guidelines/decisions, the Developer shall be considered under a condition of default, in the following events: The Developer fails to provide ready to move in possession of the Commercial shop/Unit to the Applicant(s) (successful allottee) within the time period specified above or fails to complete the project within the stipulated time disclosed at the time of registration of the Project with the concerned authority which includes the extended period, if any. For the purpose of this clause. ‘ready to move in possession’ shall mean that the Commercial Shop/Unit shall be in a habitable condition which is complete in all respect including the provision of all specifications, amenities and facilities as agreed to between the parties and for which occupation certificate or part thereof has been issued by the competent authority. Discontinuance of the Developer’s business as a developer or account of suspension or revocation of his registration under the provisions of the Real Estate Regulation Act or the rules or regulations made thereunder.
              </p>
              <p>
                In case of default by Developer under the conditions listed above, Applicant(s) (successful allottee) is entitled to the following:

                Stop making further payments to Developer as demanded by the Developer. If the Applicant(s) (successful allottee) stops making payments, the Developer shall correct the situation by completing the construction/development milestones and only thereafter the Applicant(s) successful allottee) be required to make the next payment without any interest for the period of such delay; or

                The applicant(s) shall have the option of terminating the allotment of Commercial Shop/Unit. In such case the Developer shall be liable to refund the entire money paid by the Applicant(s) (successful allottee) under any head whatsoever towards the purchase of the Commercial Shop/Unit, along with any interest as provided in rule 15 of the Haryana Real Estate Regulatory Authority, Rules 2017, within ninety days of receiving the termination notice. Provided that where an Applicant(s) (successful allottee) does not intend to withdraw from the Project or terminate the allotment of the Commercial Shop/Unit, he shall be paid interest as provided in rule 15 of the Haryana Real Estate Regulatory Authority, Rules, 2017 by the Developer for every month of delay till the handing over of the possession of the Commercial Shop/Unit, within ninety days of it becoming due. Subject to the Force Majeure Events, Court Orders, Government Policy (ies)/Guidelines/Decisions, The Applicant(s) (successful allottee) shall be considered under a condition of default in the following events:
              </p>
              <p>
                Dishonour of any cheque(s) including post-dated cheques given by the Applicant(s) (successful allottee) to the Developer for any reason whatsoever; Failure to execute the agreement, conveyance deed, maintenance agreement and / or any other documents required to be executed with the Developer within such timelines as stipulated by the Developer in terms of the Agreement/Application;
              </p>
              <p>
                Applicant(s) (successful allottee) fails to take possession of the Commercial shop/Unit within the time provided herein above; Failure to pay any taxes and other charges including stamp duty, legal charges, registration charges, any incidental charges etc. in terms of the Agreement/Application; In case of such cancellation, the allottee(s) shall have no lien or claim on the Commercial Shop and The Developer will be entitled to sell, convey or transfer the Commercial Shop/Unit to any party as its sole discretion.
              </p>
              <p>
                In case of an event of default committed by any Applicant(s) (successful allottee), The Developer will have the following options (exercisable individually or jointly at the sole discretion of the Developer):
              </p>
              <p>
                The Applicant(s) (successful allottee) shall be liable to pay interest as provided in rule 15 of the Haryana Real Estate Regulatory Authority, Rule, 2017 of the periods of delay.

                The Applicant hereby undertakes to inform the Developer of any change in his address or any other particular/information as given above, in writing, failing which the particular available in the Application shall be deemed to be correct and all the letters or any kind of communication sent at the recorded address by the Developer shall be deemed to have been received by applicant(s) and shall not be subject to any dispute of any nature. In case of any default in communication due to incorrect information, the Applicant(s) shall be liable to borne all the cost and expenses.
              </p>
              <p>
                The Applicant shall get its complete address registered with the Developer at the time of booking and shall be its responsibility to inform the Developer in writing by Registered letter for any change in its mailing or permanent address, email address, contact number etc.. If the Applicant fails to do so then failing which, all demand notices and letters posted at the first registered address will be deemed to have been received by him at the time when those should ordinarily reach at such address and shall be responsible for any default making payment and other consequences that might occur therefrom.
              </p>

              <p>
                In case or joint applicant(s), The Developer shall send all letters/notices and communications to the sole/first applicant at his address given in the application form through registered/speed post of though courier. All such letters/notices and communications so sent to the sole/first applicant shall be deemed to have been duly received by all Applicant(s) within 5 days from the date of dispatch. The Developer shall not be liable to send separate communication, letters and notices to the second Applicant(s) or to Applicant(s) other than the first Applicant(s)
              </p>

              <p>
                In case the Applicant is NRI/PIO, the Applicant further agrees that the Applicant itself shall be responsible for complying with the necessary formalities as laid down in the Foreign Exchange Management Act 1999 (FEMA), rules and regulations of the Reserve Bank of India or statutory enactments or amendments thereof and all other applicable laws including that of remittance of payments, acquisition, sale, transfer of immovable property, etc. and provide the Developer with such permissions, approvals which would enable the Developer to fulfil its obligations under this Application. The Applicant agrees that in the event of any failure on its part to comply with the applicable guidelines issued by the Reserve Bank of India, FEMA or any other law(s), the Applicant shall alone be liable for any action under the provisions of relevant Act, Regulations etc. The Developer accepts no responsibility and liability in this regard and the Applicant shall keep the Developer fully indemnified and harmless in this regard.
              </p>

              <h3>DISPUTES </h3>
              <p>
                DISPUTES All or any disputes arising out or touching upon or in relation to the terms and conditions of this Application/Agreement including the interpretation and validity of the terms and conditions thereof and the respective rights and obligations of the parties shall be settled amicably in mutual discussion.
              </p>
              <p>
                Cancellation/Refund Policy before allotment

                The registration amount INR 49,000/- would be refunded in full within 45 days to the unsuccessful applicants and the applicants not interested after the provisional allotment.
              </p>
              <h3>PRIVACY POLICY </h3>
              <p>
                <b>WHAT THIS PRIVACY POLICY COVERS</b>{" "}
              </p>
              <p>
                This privacy policy covers our usage of personally identifiable information that is shared by you with us when you visit our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us. This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage.
              </p>
              <p>
                <b>INFORMATION COLLECTION AND USE </b>
              </p>
              <p>
                We collect personally identifiable information when you submit any forms on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties. When you submit a form, we ask for your name, email address, phone number and service you need apart from what you mention in your comments. Once you submit the form and it reaches us, we save the information provided by you in our database and it is not shared with any third party. We use this information for two general purposes: to revert to your query and future reference, and to contact you about services.
              </p>
              <p>
                <b>INFORMATION SHARING AND DISCLOSURE </b>
              </p>
              <p>
                We do not sell / rent or share the personally identifiable information made available to us to anyone. We send personally identifiable information about you to other companies or people in if We have your consent to share the information; or We respond to subpoenas, court orders or legal process; or We find that your actions on our websites violate the Terms of Service.
              </p>
              <p>
                <b>CHANGES TO THIS PRIVACY POLICY </b>
              </p>
              <p>
                The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy, you can simply discontinue visiting our website. SITE SECURITY Haryana Affordable Shops has implemented adequate security measures to protect against any misuse, theft or alteration of information stored on the www.haryanaaffordableshops.com website. However, the risk of hacking remains. In case of any computer or hack attacks on the site, Haryana Affordable shops or any of its associated companies cannot be held liable for loss, theft or corruption of data maintained on the site.
              </p>
              <h3>REFUND POLICY </h3>
              <p>
                (i) Registration Amount is Fully Refundable within 45 days for Unsuccessful Applicants and Applicants not Interested after Allotment

                (ii) After the provisional allotment if any applicant wishes to withdraw then the applicant needs to visit the site office and submit a written application for the same. After the application is received the registration amount of INR 49,000/- would be refunded in full within 45 days.
              </p>
            </div>
          </div>
        </section>
      </div>
      <footer style={{ backgroundColor: "#155995" }}>
        <div className="py-4 text-white text-center px-4">
          <span>
            {" "}
            Copyrights © {new Date().getFullYear()} Haryana Approved Shops
            Gurugram - All rights reserved.
          </span>
        </div>
      </footer>
    </>
  );
};

export default SwarnimSec;
